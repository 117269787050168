<template>
  <b-container fluid class="wrapper p-5">
    <b-row
      style="
        border-radius: 20px;
        background-color: #fff;
      "
      class="p-4 ml-4 mr-4 pb-5"
    >
      <b-col >
        <div>
          <validation-observer ref="accountForm" v-slot="{ valid }">
            <b-form>
              <b-row>
                <b-col xl="3" lg="4" md="5" sm="6">
                  <h3 class="mt-5">
                    {{ $t("paymentBilling") }}
                  </h3>
                </b-col>
                <b-col xl="9" lg="8" md="7" sm="6">
                  <b-row class="no-gutters align-items-center">
                <b-col>
                  
                </b-col>
                <b-col class="col-auto">
                  <b-button
                    v-if="editBillingStatus"
                    size="sm"
                    class="text-uppercase mr-1 action-btn p-3"
                    variant="outline-gray-dark"
                    @click="cancelEditBillingForm"
                  >
                    <span>{{ $t("button.cancel") }}</span>
                  </b-button>
                  <b-button
                    v-if="editBillingStatus"
                    size="sm"
                    class="text-uppercase save-btn p-3"
                    variant="primary"
                    @click="submitBillingInfo"
                    :disabled="!valid"
                  >
                    <span>{{ $t("button.save") }}</span>
                  </b-button>
                  <b-button
                    v-else
                    size="sm"
                    class="text-uppercase action-btn p-3"
                    variant="outline-gray-dark"
                    @click="activateEditBillingForm"
                  >
                    <img
                      src="./../assets/general-icons/pencil-alt-2.svg"
                      class="action__icon clickable mr-1"
                    />
                    <span>{{ $t("button.edit") }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="no-gutters">
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('company')"
                    label-for="company"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('company')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="company"
                        class="input__field"
                        v-model="clientBillingInfo.companyName"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.companyName }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('country')"
                    label-for="country"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('country')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-select
                        id="country"
                        class="input__field"
                        v-model="clientBillingInfo.country"
                        :options="countryList"
                        :state="getValidationState(validationContext)"
                        value-field="name"
                        text-field="name"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled>
                            {{ $t("selectAnOption") }}
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.country }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('city')"
                    label-for="city"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('city')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="city"
                        class="input__field"
                        v-model="clientBillingInfo.city"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.city }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('address')"
                    label-for="address"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('address')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="address"
                        class="input__field"
                        v-model="clientBillingInfo.address"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.address }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('province')"
                    label-for="province"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('province')"
                      :rules="{ required: true, length: 2 }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="province"
                        class="input__field"
                        v-model="clientBillingInfo.province"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.province }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('VATNumber')"
                    label-for="VATNumber"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('VATNumber')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="VATNumber"
                        class="input__field"
                        v-model="clientBillingInfo.vat"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.vat }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('sdlCode')"
                    label-for="sdlCode"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('sdlCode')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="sdlCode"
                        class="input__field"
                        v-model="clientBillingInfo.sdlCode"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.sdlCode }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('PEC')"
                    label-for="PEC"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('PEC')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="PEC"
                        class="input__field"
                        v-model="clientBillingInfo.pec"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.pec }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('zip')"
                    label-for="zip"
                  >
                    <validation-provider
                      v-if="editBillingStatus"
                      immediate
                      :name="$t('zip')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="zip"
                        class="input__field"
                        v-model="clientBillingInfo.zip"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <div v-else class="text-muted mt-n2">
                      {{ clientBillingInfo.zip }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
                </b-col>
              </b-row>
              
            </b-form>
          </validation-observer>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Utils from "./../Utils";
export default {
  watch: {
    newsletter: {
      handler: function () {
        if (this.dataLoaded) this.changeNewsletterPreference();
      },
      immediate: false,
    },
  },
  data() {
    return {
      dataLoaded: false,
      countryList: Utils.COUNTRY_LIST,
      editAccountStatus: false,
      editBillingStatus: false,
      clientAccountInfo: {},
      clientBillingInfo: {},
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
      newsletter: false,
      privacyLinks: {
        hrefEn: "https://emotiva.it/en/empower-privacy-policy",
        hrefIt: "https://emotiva.it/empower-privacy-policy-2/",
      },
    };
  },
  created() {
    this.initAccountInfo();
    this.initBillingInfo();
    this.initNewsletter();
  },
  computed: {
    userAccount() {
      return { ...this.$store.getters["auth/getUserAccount"] };
    },
  },
  methods: {
    getExternalLink(linkItem) {
      return Utils.getExternalLinkByLang({
        linkEn: linkItem.hrefEn,
        linkIt: linkItem.hrefIt,
      });
    },
    initAccountInfo() {
      this.clientAccountInfo = {
        firstName: this.userAccount.firstName,
        lastName: this.userAccount.lastName,
        email: this.userAccount.registration?.email
          ? this.userAccount.registration.email
          : "-",
        jobTitle: this.userAccount.jobTitle,
        phone: this.userAccount.phone,
      };
      this.editAccountStatus = false;
    },
    initBillingInfo() {
      this.clientBillingInfo = {
        companyName: this.userAccount.companyName,
        country: this.userAccount.country,
        city: this.userAccount.city,
        address: this.userAccount.address,
        province: this.userAccount.province,
        vat: this.userAccount.vat,
        sdlCode: this.userAccount.sdlCode,
        pec: this.userAccount.pec,
        zip: this.userAccount.zip,
      };
      this.editBillingStatus = false;
    },
    initNewsletter() {
      // Use 'dataLoaded' in order to prevent send request on init (in watch)
      this.$nextTick(() => {
        this.dataLoaded = true;
      });
      this.newsletter = this.userAccount.newsletterRegistration;
    },
    getValidationState({ dirty, validated, valid = null, untouched }) {
      // if (!untouched && (dirty || validated)) return valid ? null : valid;
      // else return null;
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    activateEditAccountForm() {
      this.editAccountStatus = true;
    },
    cancelEditAccountForm() {
      this.initAccountInfo();
    },
    submitAccount() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.firstName = this.clientAccountInfo.firstName;
      currentUserAccount.lastName = this.clientAccountInfo.lastName;
      // Is this actually needed if email is not mutable after initial user creation? Disabling for now
      // currentUserAccount.registration.email = this.clientAccountInfo.email;
      currentUserAccount.jobTitle = this.clientAccountInfo.jobTitle;
      currentUserAccount.phone = this.clientAccountInfo.phone;
      this.updateUserAccount(currentUserAccount, this.initAccountInfo);
    },
    activateEditBillingForm() {
      this.editBillingStatus = true;
    },
    cancelEditBillingForm() {
      this.initBillingInfo();
    },
    submitBillingInfo() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.companyName = this.clientBillingInfo.companyName;
      currentUserAccount.country = this.clientBillingInfo.country;
      currentUserAccount.city = this.clientBillingInfo.city;
      currentUserAccount.address = this.clientBillingInfo.address;
      currentUserAccount.province = this.clientBillingInfo.province;
      currentUserAccount.vat = this.clientBillingInfo.vat;
      currentUserAccount.sdlCode = this.clientBillingInfo.sdlCode;
      currentUserAccount.pec = this.clientBillingInfo.pec;
      currentUserAccount.zip = this.clientBillingInfo.zip;
      this.updateUserAccount(currentUserAccount, this.initBillingInfo);
    },
    changePassword() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("auth/changeUserPassword", {
          oldPassword: this.currentPassword,
          newPassword: this.newPassword,
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    changeNewsletterPreference() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.newsletterRegistration = this.newsletter;
      this.updateUserAccount(currentUserAccount);
    },
    updateUserAccount(userAccount, resetClb = null) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store.commit("auth/SET_USER_ACCOUNT", userAccount);
      this.$store
        .dispatch("auth/updateClient")
        .then(() => {
          if (resetClb) resetClb();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
  },
};
</script>
<style scoped>
.divider {
  border-top: 1px black solid;
}
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
.input__field.form-control:disabled {
  border: unset;
  background-color: initial;
  padding: 0;
  color: #6c757d;
  margin-top: -16px;
}
.input__field{
  height: 48px;
}
.secondary__btn.btn-secondary.disabled,
.secondary__btn.btn-secondary:disabled {
  color: black;
}
.action-btn{
  border-radius: 46px;
  min-width:100px ;

}
.save-btn{
  border-radius: 46px;
  background-color: var(--emotiva-pink);
  min-width:90px ;
  color:#fff;
}
</style>
